<template>
  <v-sheet class="notification" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col md="3" class="pr-0">
        <v-card class="mx-auto" style="border: 0.5px solid #90caf9">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label me-0"
                  style="font-weight: 600; font-size: 18px"
                >
                  <VAvatar :size="55" rounded color="blue lighten-5" variant="tonal" class="me-1">
                    <v-icon size="34" color="blue darken-4" dark>mdi-bell</v-icon>
                  </VAvatar>
                  All</v-list-item-title
                >
              </v-list-item-content>
              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="#0D47A1" style="font-size: 18px; color: white; font-weight: 600"
                    >145</v-chip
                  >
                </span>
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="3" class="pr-0">
        <v-card class="mx-auto" style="border: 0.5px solid #ef9a9a">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label"
                  style="font-weight: 600; font-size: 16px"
                >
                  <VAvatar :size="55" rounded color="red lighten-5" variant="tonal" class="me-1">
                    <v-icon size="34" color="red" dark>mdi-calendar</v-icon>
                  </VAvatar>
                  This Month</v-list-item-title
                >
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="#F44336" style="font-size: 18px; color: white; font-weight: 600"
                    >5</v-chip
                  >
                </span>
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="3" class="pr-0">
        <v-card class="mx-auto" style="border: 0.5px solid orange">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label"
                  style="font-weight: 600; font-size: 16px"
                >
                  <VAvatar :size="55" rounded color="orange lighten-5" variant="tonal" class="me-1">
                    <v-icon size="34" color="orange" dark>mdi-calendar</v-icon>
                  </VAvatar>
                  Last Month</v-list-item-title
                >
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="orange" style="font-size: 18px; color: #fff; font-weight: 600"
                    >20</v-chip
                  ></span
                >
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="3">
        <v-card class="mx-auto" style="border: 0.5px solid #81c784">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label"
                  style="font-weight: 600; font-size: 16px"
                >
                  <VAvatar :size="55" rounded color="green lighten-5" variant="tonal" class="me-1">
                    <v-icon size="34" color="green" dark>mdi-calendar</v-icon>
                  </VAvatar>
                  <span style="max-width: 92px; display: inline-block">Last 4 Month Ave..</span>
                </v-list-item-title>
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading"
                  ><v-chip color="green" style="font-size: 18px; color: white; font-weight: 600"
                    >45</v-chip
                  ></span
                >
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-card-text class="px-0 py-0">
      <v-row>
        <v-col md="6" class="my-auto">
          <h1 class="custom-header-blue-text m-0">Notification</h1>
        </v-col>
        <v-col class="justify-content-end pt-0">
          <v-flex class="d-flex justify-content-end">
            <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      color="blue darken-4"
                      depressed
                      tile
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      class="white--text"
                    >
                      <v-icon>edit_note</v-icon>
                    </v-btn>
                  </template>
                  <span>Columns arrangement and visibility</span>
                </v-tooltip>
              </template>
              <v-list nav dense>
                <v-list-item
                  draggable
                  @dragstart="dragstart(index)"
                  @dragover="dragover(index)"
                  @dragend="dragEnd"
                  v-for="(item, index) in notificationTableHeading"
                  :key="item.title + '_' + index"
                  class="flex-column column-arrangement-list-item cursor-move"
                >
                  <div class="d-flex flex-row">
                    <v-checkbox
                      :ripple="false"
                      hide-details
                      color="#0d47a1"
                      v-model="item.status"
                      class="m-0"
                    ></v-checkbox>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-icon>drag_indicator</v-icon>
                  </div>
                  <v-divider
                    v-if="index < notificationTableHeading.length - 1"
                    class="mt-1 mb-0"
                    style="width: 100%"
                  ></v-divider>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              :disabled="pageLoading"
              color="blue darken-4"
              depressed
              tile
              class="ml-2 white--text"
              :to="{ name: 'blast-notification-create', query: { t: new Date().getTime() } }"
            >
              New Notification
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-card-text>
    <SearchCriteria
      v-if="listingSearch"
      :search="listingSearch"
      search-string="Notofication #, Full Name, Email, Phone No. and Address"
      v-on:close-search="resetSearch"
    >
    </SearchCriteria>
    <v-simple-table fixed-header class="bt-table table_height">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="heading in notificationTableHeading"
              :key="heading.title"
              style="background-color: #f5f5f5"
            >
              <div v-if="heading.status">{{ heading.title }}</div>
            </th>
          </tr>
        </thead>
        <tbody v-if="!pageLoading">
          <template v-if="notifications.length">
            <tr
              v-for="(row, bkey) in notificationTableData"
              :key="bkey"
              :class="bkey % 2 === 0 && 'blue lighten-5'"
              class="notification-listing-tr"
            >
              <td
                v-for="(th, index) in notificationTableHeading"
                :key="'key_' + index"
                :width="row[getColValue(th.title)]?.key == 'IMAGES' ? '50px' : ''"
              >
                <div v-if="getColValue(th.title) === 'title' && th.status">
                  <ValueTemplate
                    v-model="row[getColValue(th.title)].value"
                    :title="row[getColValue(th.title)].key"
                  ></ValueTemplate>
                  <!-- {{ row[getColValue(th.title)]?.value }} -->
                </div>

                <div v-else-if="getColValue(th.title) === 'action' && th.status">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        class="mx-2"
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        v-on:click.stop.prevent="routeToUpdate(row.id)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        v-on:click.stop.prevent="deleteConfirm(row)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </div>
                <div v-else-if="getColValue(th.title) === 'images' && th.status">
                  <v-avatar>
                    <img :src="row[getColValue(th.title)].value" alt="John" />
                  </v-avatar>
                </div>
                <div v-else-if="getColValue(th.title) === 'text' && th.status">
                  {{ addElipses(text, 80).text }} &nbsp;
                  <span
                    v-if="addElipses(text, 20).readmore"
                    style="color: #24326d; cursor: pointer; font-size: 12px"
                    @click="dialog = true"
                    >Read More</span
                  >
                </div>
                <div v-else-if="getColValue(th.title) === 'anchorurl' && th.status">
                  {{ addElipses(text, 20).text }} &nbsp;
                  <span
                    v-if="addElipses(text, 20).readmore"
                    style="color: #24326d; cursor: pointer; font-size: 12px"
                    @click="dialog = true"
                    >Read More</span
                  >
                </div>

                <div v-else-if="getColValue(th.title) === 'blast_date' && th.status">
                  <ValueTemplate
                    v-model="row[getColValue(th.title)].value"
                    :title="row[getColValue(th.title)].key"
                  ></ValueTemplate>
                  <!-- {{ row[getColValue(th.title)]?.value }} -->
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="9">
                <p class="m-0 text-center">
                  <img
                    width="30"
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image mr-4"
                  />
                  Uhh... There are no notification at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot v-else>
          <tr v-for="idr in 9" :key="`_$skeleton_$_locader_${idr}`">
            <td v-for="idk in 9" :key="idk">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <v-layout v-if="notifications.length" class="light-border-top mt-4">
      <v-flex md6>
        <label class="btx-label p-4"
          >Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
        >
      </v-flex>
      <v-flex md6>
        <v-pagination
          color="blue darken-4"
          v-model="currentPage"
          :length="pageLimit"
          :total-visible="7"
        ></v-pagination>
      </v-flex>
    </v-layout>

    <Dialog :dialog="dialog" :dialogWidth="900">
      <template v-slot:title>Desctiption</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <img
                width="70"
                height="70"
                src="https://cdn.pixabay.com/photo/2023/07/04/18/10/wreath-8106795_640.png"
              />
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0"><b class="red--text text--lighten-1"></b>{{ text }}</p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn depressed tile @click="dialog = false"> Close </v-btn>
      </template>
    </Dialog>

    <OrderTemplate
      v-model="order"
      :customer-id="promotionId"
      :dialog="orderDialog"
      v-on:close="orderDialog = false"
      v-on:save="saveOrder()"
      v-on:collect:voucher="getAvailableVouchers"
      v-on:claim:voucher="getCollectedVouchers"
    >
    </OrderTemplate>
    <CollectTemplate
      :dialog="collectDialog"
      :order-id="cOrderId"
      :order-code="cOrderCode"
      :vouchers="cVouchers"
      v-on:close="vCollected()"
      v-on:collected="vCollected()"
    ></CollectTemplate>
    <RedeemTemplate
      :dialog="redeemDialog"
      :order-id="rOrderId"
      :order-code="rOrderCode"
      :vouchers="rVouchers"
      v-on:close="vRedeemed()"
      v-on:redeemed="vRedeemed()"
    ></RedeemTemplate>
    <DeleteTemplate
      type="promotion"
      delete-note="All leave of this employee will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="getnotifications()"
    >
    </DeleteTemplate>
    <SearchTemplate
      :dialog="allCollectedDialog"
      v-on:close="allCollectedDialog = false"
    ></SearchTemplate>
    <Dialog dense :dialog="whatsappPromotional" v-if="whatsappPromotional">
      <template v-slot:title> Whatsapp Promotional </template>
      <template v-slot:body>
        <div class="mx-6" fluid>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">Template</label>
            </v-col>
            <v-col md="8">
              <SelectInput
                hide-details
                hideTopMargin
                :loading="pageLoading"
                :disabled="pageLoading"
                item-text="text"
                dense
                item-value="value"
                :items.sync="templateList"
                v-model="whatsapp.template"
              ></SelectInput>
            </v-col>
          </v-row>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">File</label>
            </v-col>
            <v-col md="8">
              <v-file-input
                v-model="whatsapp.files"
                ref="fileInput"
                hide-details
                id="item-file-upload"
                label="Upload Images"
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          @click="whatsappPromotional = false"
        >
          Send
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile v-on:click="whatsappPromotional = false">
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import SearchCriteria from "@/view/components/SearchCriteria";
import { GET_CUSTOMERS, GET_TEMPLATE_LIST } from "@/core/lib/customer.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import OrderTemplate from "@/view/components/OrderTemplate";
import CollectTemplate from "@/view/components/CollectVoucherTemplate";
import ValueTemplate from "@/view/components/ValueTemplate";
import RedeemTemplate from "@/view/components/RedeemVoucherTemplate";
import SearchTemplate from "@/view/components/SearchAllVouchers";
import {
  SET_SEARCH_TITLE,
  SET_SEARCH_MODEL,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
import SelectInput from "@/view/components/SelectInput.vue";

export default {
  name: "notification-listing",
  title: "Listing Notification",
  data() {
    return {
      text: "Also called a company description, your business description summarizes what your company does, its purpose, and what makes it unique. It's a critical component of your business plan and immediately follows your executive summary",
      dialog: false,
      notificationTableHeading: [
        // { title: "ACTION", status: true },
        { title: "IMAGES", status: true },
        { title: "TITLE", status: true },
        { title: "TEXT", status: true },
        { title: "ANCHOR URL", status: true },
        { title: "BLAST DATE", status: true },
      ],
      notificationTableData: [
        {
          code: "XMH36",
          //   action: { value: "", key: "ACTION" },
          title: { value: "BUSINESS", key: "TITLE" },
          text: { value: "TEXT", key: "TEXT" },
          anchorurl: { value: "ANCHOR URL", key: "ANCHOR URL" },
          blast_date: { value: "8/08/2023", key: "BLAST DATE" },
          images: {
            value: "https://cdn.pixabay.com/photo/2023/07/04/18/10/wreath-8106795_640.png",
            key: "IMAGES",
          },
        },
        {
          code: "XMQ96",
          //   action: { value: "", key: "ACTION" },
          title: { value: "CAP STOCKER", key: "TITLE" },
          text: { value: "TEXT", key: "TEXT" },
          anchorurl: { value: "ANCHOR URL", key: "ANCHOR URL" },
          blast_date: { value: "8/08/2023", key: "BLAST DATE" },
          images: {
            value:
              "https://plus.unsplash.com/premium_photo-1661953124283-76d0a8436b87?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cmVzdGF1cmFudHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
            key: "IMAGES",
          },
        },
      ],
      dragStartIndex: null,
      dragOverIndex: null,
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      whatsapp: {
        files: [],
        template: "first",
      },
      speedDial: [],
      whatsappPromotional: false,
      notifications: [],
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      promotionId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },
  watch: {
    tableHeading: {
      handler: function (newval) {
        console.log(newval);
      },
      deep: true,
    },
    currentPage() {
      this.getnotifications();
    },
    listingSearch() {
      this.getnotifications();
    },
    filterDateRange() {
      if (this.filterDateRange && this.filterDateRange.length > 1) {
        this.filterType = "all";
        this.getnotifications();
      }
    },
  },
  methods: {
    getColValue(val) {
      if (val.includes(" ")) {
        return val.split(" ").join("_").toLowerCase();
      } else {
        return val.toLowerCase();
      }
    },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.notificationTableHeading[this.dragStartIndex];
      this.notificationTableHeading.splice(this.dragStartIndex, 1);
      this.notificationTableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    addElipses(text, words) {
      if (text.length > 5) {
        if (words === 0) {
          return { text: text.slice(0, text.length), readmore: false };
        } else {
          return { text: text.slice(0, words) + "...", readmore: true };
        }
      } else {
        return { text: text.slice(0, text.length), readmore: false };
      }
    },
    getAllCollectedVouchers() {},
    // changeDatePicker() {
    //   if (this.filterDateRange[0] && this.filterDateRange[1]) {
    //     this.getnotifications()
    //   }
    // },
    filterTypeChange() {
      this.filterDateRange = null;
      // this.getnotifications();
    },
    saveOrder() {
      this.promotionId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.promotionId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, code) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = code;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, code) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = code || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.promotionId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "notification-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "notification-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ code, id }) {
      this.deleteText = code;
      this.deleteEndpoint = `notification/${id}`;
      this.deleteDialog = true;
    },
    async getnotifications() {
      try {
        let form = {
          current_page: this.currentPage,
          search: this.listingSearch,
          filter_type: this.filterType,
        };
        if (this.filterDateRange) {
          const dateRange = {
            blast_date: this.filterDateRange[0],
            end_date: this.filterDateRange[1],
          };
          form = { ...form, ...dateRange };
        }
        this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_CUSTOMERS(form);
        this.notifications = rows;
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {
          this.$store.dispatch(SET_SEARCH_MODEL, null);
        });
    },
    async getTemplateListing() {
      try {
        this.pageLoading = true;
        console.log("getTemplateListing");
        const data = await GET_TEMPLATE_LIST();
        console.log("data", data);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    this.getnotifications();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Notification");
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    DeleteTemplate,
    OrderTemplate,
    CollectTemplate,
    RedeemTemplate,
    SearchTemplate,
    SearchCriteria,
    SelectInput,
    Dialog,
    ValueTemplate,
  },
  computed: {
    ...mapGetters(["listingSearch"]),
  },
};
</script>

<style>
.table_height .v-data-table__wrapper {
  height: calc(100vh - 300px);
}

.notification-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 5;
}

.notification-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}
</style>
